<template>
  <div class="permissions">
    <button
      v-if="screenWidth && screenWidth < 800"
      class="permissions__button"
      @click="changeView('RolePermissions')"
    >
      <i class="ion ion-chevron-back-outline"></i>
      {{ $t("accountManagement.back") }}
    </button>
    <div class="permissions__title">
      {{ title }}
    </div>
    <div
      class="permissions-role"
      v-for="permission in permissions"
      :key="permission.id"
    >
      <span class="permissions-role__text">{{ permission.signature }}</span>
      <b-form-checkbox
        class="permissions-role__switch"
        v-model="permissionsVmodel[permission.id]"
        @change="updatePermisssion(permission, $event)"
        name="check-button"
        switch
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onReSize from "../../../../Extend/onResize";

export default {
  /* eslint-disable prefer-destructuring */
  name: "Permissions",
  mixins: [onReSize],
  props: {
    roleInfo: Object,
  },
  data() {
    return {
      permissions: null,
      allRolePermissions: null,
      permissionsVmodel: {},
      title: null,
      roleId: null,
    };
  },
  computed: {
    ...mapGetters({
      getRoleSelected: "role/getRoleSelected",
    }),
  },
  watch: {
    roleInfo: {
      immediate: true,
      async handler() {
        await this.getPermissionsInfo();
        await this.permissionByRole(
          this.$store.getters["role/getRoleSelected"]
        );
      },
    },
  },
  methods: {
    async getPermissionsInfo() {
      const permissionsRequest = [
        this.$store.dispatch("rolePermissions/getAllPermissions"),
        this.$store.dispatch("rolePermissions/getAllRolePermissions"),
      ];
      await Promise.all(permissionsRequest).then((values) => {
        this.permissions = values[0];
        this.allRolePermissions = values[1];
      });
    },
    async permissionByRole(data) {
      this.title = data ? data.name : this.$route.query.name;
      this.roleId = data ? data.id : parseInt(this.$route.params.roleId, 10);
      this.resetSwitch();
      const permissionsByRole = this.allRolePermissions.filter(
        (el) => el.role_id === this.roleId
      );
      permissionsByRole.forEach((item) => {
        this.permissionsVmodel[item.permission_id] = true;
      });
    },
    resetSwitch() {
      this.permissions.forEach((item) => {
        this.permissionsVmodel[item.id] = false;
      });
    },
    async updatePermisssion(data, event) {
      setTimeout(async () => {
        if (event) {
          const payload = {
            role_id: this.roleId,
            permission_id: data.id,
          };
          const createRolePermission = await this.$store.dispatch(
            "rolePermissions/createRolePermissions",
            payload
          );
          this.allRolePermissions.push(createRolePermission);
          this.$store.commit(
            "rolePermissions/setRolesPermissions",
            this.allRolePermissions
          );
        } else {
          const rolePermissionSelected = this.allRolePermissions.find(
            (el) => el.role_id === this.roleId && el.permission_id === data.id
          ).id;
          await this.$store.dispatch(
            "rolePermissions/deleteRolePermissions",
            rolePermissionSelected
          );
          const deleteRolePermission = this.allRolePermissions.splice(
            this.allRolePermissions.findIndex(
              (item) => item.id === rolePermissionSelected
            ),
            1
          );
          this.$store.commit(
            "rolePermissions/setRolesPermissions",
            deleteRolePermission
          );
        }
      }, 800);
    },
    async changeView(routeName) {
      await this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions {
  padding: 1rem 3rem;
  height: 730px;
  overflow-y: auto;
  @extend %scroll-bar-styles;
  &__title {
    width: 100%;
    color: $color-border-container;
    margin-bottom: 1rem;
    padding-right: 3rem;
    text-align: left;
    font-weight: 600;
  }
  &__button {
    border: none;
    background-color: inherit;
    font-weight: bold;
    color: $color-primary-company;
    margin-bottom: 2rem;
    width: 100%;
    text-align: start;
    padding: 0;
    i {
      color: $color-primary-company;
      font-weight: bold;
      margin-right: 2px;
    }
  }
}
.permissions-role {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 0.625rem;
}

::v-deep {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color-border-container;
    border-color: $color-border-container;
  }
}

@media (min-width: 1500px) {
  .permissions {
    padding: 1rem 8rem;
  }
}
@media (min-width: 2000px) {
  .permissions {
    padding: 1rem 15rem;
  }
}

@media (max-width: 800px) {
  .permissions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    padding-left: 2rem;
    height: auto;
    overflow: hidden;
    &__title {
      width: 490px;
    }
  }
  .permissions-role {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    &__text {
      width: 450px;
    }
  }
}
@media (max-width: 550px) {
  .permissions {
    &__title {
      width: 370px;
    }
  }
  .permissions-role {
    span {
      width: 330px;
    }
  }
}
@media (max-width: 430px) {
  .permissions {
    &__title {
      width: 310px;
    }
  }
  .permissions-role {
    span {
      width: 280px;
    }
  }
}
@media (max-width: 380px) {
  .permissions {
    &__title {
      width: 250px;
    }
  }
  .permissions-role {
    span {
      width: 220px;
    }
  }
}
</style>
