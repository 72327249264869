<template>
  <div class="role-list">
    <h6 class="role-list__title">
      {{ $t("accountManagement.roleList") }}
    </h6>
    <button
      :class="selectedRow === role.id ? selectedOption : ''"
      class="role-option"
      v-for="role in roles"
      :key="role.id"
      @click="roleSelected(role)"
    >
      <h6>{{ role.name }}</h6>
      <span>{{ role.description }}</span>
    </button>
  </div>
</template>

<script>
import onReSize from "../../../../Extend/onResize";

export default {
  name: "Roles",
  mixins: [onReSize],
  data() {
    return {
      roles: null,
      permissionsTitle: null,
      firstRole: null,
      selectedRow: null,
      selectedOption: null,
    };
  },
  async created() {
    this.roles = await this.$store.dispatch("role/getAllRoles");
    const [firstRole] = this.roles;
    this.firstRole = firstRole;
    this.selectedRow = this.firstRole.id;
    this.selectedOption = "selected";
    await this.roleId(this.firstRole);
    if (this.screenWidth && this.screenWidth < 800) {
      this.selectedOption = "";
    }
    this.$emit("disableLoading");
  },
  methods: {
    async roleSelected(data) {
      this.selectedRow = data.id;
      await this.roleId(data);
      this.selectedOption = "selected";
      if (this.screenWidth && this.screenWidth < 800) {
        await this.changeView(data);
      }
    },
    async roleId(data) {
      this.$store.commit("role/setRoleSelected", data);
      this.$emit("roleSelected", data);
      if (this.screenWidth && this.screenWidth >= 800) {
        const params = { rolePermisionId: data.id };
        await this.$router.push({ params }).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      } else {
        const params = { roleId: data.id };
        await this.$router.push({ params }).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
    },
    async changeView(data) {
      this.$router.push({
        name: "Permissions",
        params: { roleId: data.id },
        query: { name: data.name },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.role-list {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    width: 97%;
    text-align: left;
    color: $color-border-container;
    margin-bottom: 2rem;
    margin-left: 2rem;
    font-weight: 600;
  }
}

.role-option {
  width: 97%;
  height: 130px;
  text-align: left;
  padding: 1rem;
  color: $color-primary-company;
  border: 1px solid $color-border-container;
  background-color: $color-gray-light;
  border-radius: 15px;
  margin-bottom: 1rem;
  h6 {
    font-weight: bold;
  }
}
.selected {
  background: $color-border-container;
  color: $color-white;
}
</style>
