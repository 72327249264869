<template>
  <div>
    <b-overlay :show="loading" class="overlay-div" rounded="sm">
      <div
        :class="
          screenWidth >= 800
            ? 'roles-permissions'
            : 'roles-permissions roles-permissions--responsive'
        "
      >
        <Roles
          class="roles-permissions__role-list"
          @disableLoading="disableLoading"
          @roleSelected="roleSelected"
        />
        <div
          class="divider"
          v-if="screenWidth && screenWidth >= 800 && !loading"
        >
          <Permissions
            class="roles-permissions__permissions-list"
            v-if="roleInfo"
            :roleInfo="roleInfo"
          />
        </div>
      </div>
      <template #overlay>
        <div class="loading" v-show="loading">
          <b-icon
            icon="truck"
            font-scale="3"
            animation="cylon"
            class="loading__text"
          ></b-icon>
          <p class="loading__text">{{ $t("loading") }}...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Permissions from "./Permissions.vue";
import Roles from "./Roles.vue";
import onReSize from "../../../../Extend/onResize";

export default {
  name: "RolePermissions",
  mixins: [onReSize],
  components: {
    Permissions,
    Roles,
  },
  data() {
    return {
      loading: true,
      titlePermission: null,
      roleInfo: null,
    };
  },
  methods: {
    roleSelected(data) {
      this.roleInfo = data;
    },
    disableLoading() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.roles-permissions {
  display: grid;
  grid-template-columns: 50% 50%;
  color: $color-primary-company;
  @include font-small-standart-text;
  &--responsive {
    grid-template-columns: 100%;
  }
}

.divider {
  display: inline-block;
  height: auto;
  border-left: 1px solid $color-border-container;
  padding-left: 1rem;
}
.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
